import React from "react";
import { emailToHSL, Icon, Marble } from "@hoylu/client-common";
import { Localized } from "../strings";
import { useSelector } from "react-redux";
import { CopyIcon } from "./modals/sharing/icons/CopyIcon";
import Styles from "./ProjectSection.module.css";
import Scrollbar from "@hoylu/client-common/dist/esm/assets/css.modules/scrollbar.module.css";
import { projectDeepLink } from "../utils/url.selectors";

export type ProjectSectionProps = {
  onEdit: () => void;
  collaborators: string[];
  canEdit: boolean;
};

const ProjectSection: React.FC<ProjectSectionProps> = ({
  onEdit,
  collaborators,
  canEdit,
}) => {
  const strings = Localized.object("SECTIONS.PROJECT");
  const deepLink = useSelector(projectDeepLink);

  const generateMarble = (collaboratorEmail: string) => {
    const initial = collaboratorEmail[0];
    const projectMarbleStyles = {
      width: "3rem",
      minWidth: "3rem",
      height: "3rem",
      fontSize: "var(--global--size-h1)",
      backgroundColor: emailToHSL(collaboratorEmail),
      boxShadow: "var(--global--shadow--lg)",
    };

    return (
      <Marble
        key={`marble-${collaboratorEmail}`}
        title={collaboratorEmail}
        style={projectMarbleStyles}
      >
        {initial}
      </Marble>
    );
  };

  const editIconStyle = {
    color: "var(--theme-text)",
    fontSize: "var(--global--size-x-large)",
  };

  return (
    <div className={Styles.projectSection}>
      <div
        className={`${Styles.collaborators} ${Scrollbar.normal} ${Scrollbar.horizontal}`}
        tabIndex={0}
        aria-label={strings.COLLABORATORS}
      >
        {collaborators.map(generateMarble)}
      </div>
      <div title={strings.COPY_LINK} tabIndex={0}>
        <CopyIcon
          icon={"hoylu-ui-icons-link"}
          style={editIconStyle}
          text={deepLink}
        />
      </div>
      {canEdit && (
        <div title={strings.PROJECT_SETTINGS} tabIndex={0}>
          <Icon
            icon={"hoylu-ui-icons-setting"}
            style={editIconStyle}
            onClick={onEdit}
          />
        </div>
      )}
    </div>
  );
};

export default ProjectSection;
