export const focusWorkspace = (id: string) => {
  var iframe = document.getElementById(id) as HTMLIFrameElement;
  var iWindow = iframe?.contentWindow;
  iWindow?.focus();
};

export const updateDocumentTitle = (title: string) => {
  document.title = title;

  if(document.querySelector('meta[property="og:title"]')) {
    document.querySelector('meta[property="og:title"]')!.setAttribute("content", title);
  }

  if(document.querySelector('meta[property="og:url"]')) {
    document.querySelector('meta[property="og:url"]')!.setAttribute("content", document.location.href);
  }

};

export const getDocumentTitle = () => {
  return document.title;
};
