import { user } from "../../state/user/user.selector";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "./Modal";
import React, { useCallback } from "react";
import { Localized } from "../../strings";
import {
  activateDashboardOption,
  cancelDashboardOption,
} from "../../state/workspaces/workspaces.actions";
import { DashboardOption } from "../../state/workspaces/DashboardOption";
import { callWithLoginConfirm } from "../../state/user/user.actions";
import { managementPortal } from "../../state/config/config.selector";
import { Button, DraggableWindowHeader } from "@hoylu/client-common";
import Styles from "./ManageAccount.module.css";

export const ManageAccount = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(user);
  const managementPortalUrl = useSelector(managementPortal);

  const strings = Localized.object("MANAGE_ACCOUNT");
  const close = useCallback(() => dispatch(cancelDashboardOption()), [
    dispatch,
  ]);
  return (
    <Modal handleOutsideClick={close}>
      <DraggableWindowHeader title={strings.TITLE} onClose={close} />
      <div className={Styles.manageAccount}>
        <div className={Styles.row}>
          <span>{strings.EMAIL}</span>
          <span>{currentUser.profile.email}</span>
        </div>
        <div className={Styles.row}>
          <span>{strings.CREATED}</span>
          {currentUser.profile.createdTime && (
            <span>
              {new Date(currentUser.profile.createdTime).toLocaleDateString()}
            </span>
          )}
        </div>
      </div>
      <div className={Styles.footer}>
        <Button
          type={"secondary"}
          toolTip={strings.DELETE}
          onClick={() =>
            dispatch(
              callWithLoginConfirm({
                accept: activateDashboardOption({
                  optionType: DashboardOption.CONFIRM_ACCOUNT_DELETE,
                }),
                reject: cancelDashboardOption(),
              })
            )
          }
        >
          {strings.DELETE + "..."}
        </Button>
        <Button
          type={"primary"}
          toolTip={strings.MANAGE}
          onClick={() => window.open(`${managementPortalUrl}/u`, "_blank")}
        >
          {strings.MANAGE}
        </Button>
      </div>
    </Modal>
  );
};
