import React from "react";
import { Button } from "@hoylu/client-common";
import { Localized } from "../../../strings";
import Styles from "./CreditLimiSection.module.css";

export const CreditLimitSection = () => {
  const learnMore = Localized.string("USER_PROFILE_WIDGET.LEARN_MORE");
  const strings = Localized.object("CREDIT_LIMIT_WARNING_BANNER");

  return (
    <div
      className={Styles.creditLimitSection}
      data-test-id="ws-info-credit-limit-banner"
    >
      <div className={Styles.header}>
        <div className={`${Styles.icon} hoylu-ui-icons-warning`} />
        <span className={Styles.title}>{strings.TITLE}</span>
      </div>
      <div>
        <Button
          toolTip={learnMore}
          type="secondary"
          onClick={() => window.open("https://www.hoylu.com/plans", "_blank")}
          additionalStyles={{ color: "var(--global--text--Color)" }}
          dataTestId="ws-info-plan-redirect-button"
        >
          {learnMore}
        </Button>
        <Button
          toolTip={strings.BUTTON}
          type="primary"
          onClick={() =>
            window.open("https://manage.hoylu.com/u/billing", "_blank")
          }
          additionalStyles={{ marginRight: 0 }}
          dataTestId="ws-info-billing-redirect-button"
        >
          {`${strings.BUTTON}...`}
        </Button>
      </div>
    </div>
  );
};
