import React from "react";
import { connect } from "react-redux";
import headerGraphic from "../../../assets/images/error-illustration.svg";
import { ErrorDialogWithImage } from "./ErrorDialogWithImage";
import { clearError } from "../../../state/mode/mode.actions";
import { Localized } from "../../../strings";

const mapDispatchToProps = {
  clearError,
};

type CreditLimitReachedDialogProps = typeof mapDispatchToProps;

export const CreditLimitReachedDialog: React.FC<CreditLimitReachedDialogProps> = ({
  clearError,
}) => {
  const strings = Localized.object("CREDIT_LIMIT_REACHED_DIALOG");

  const redirectToBillingPage = () => {
    window.open("https://manage.hoylu.com/u/billing", "_newtab");
    clearError();
  }

  return (
    <ErrorDialogWithImage
      onCancel={clearError}
      imageUrl={headerGraphic}
      title={strings.TITLE}
      buttonText={strings.BUTTON_TEXT}
      onButtonClick={redirectToBillingPage}
      showExternalLinkSymbol={true}
      buttonCustomStyles={{ backgroundColor: "var(--global--attention--Background)", color: "#FFFFFF" }}
    />
  );
};

export default connect(undefined, mapDispatchToProps)(CreditLimitReachedDialog);
