import React from "react";
import { Localized } from "../strings";
import { connect, useDispatch } from "react-redux";
import { activateDashboardOption } from "../state/workspaces/workspaces.actions";
import { DashboardOption } from "../state/workspaces/DashboardOption";
import { RootState } from "typesafe-actions";
import {
  enableWorkspaceExport,
  managementPortal,
  showCreditLimitUi,
} from "../state/config/config.selector";
import { logout } from "../state/user/user.actions";
import { isOrganizationAdmin } from "../utils/sharing.helpers";
import {
  effectiveTonnageUsage,
  getUserLicenseType,
  isCreditLimitReached,
  isUserLicensed,
} from "../state/workspaces/workspaces.selector";
import { CircularProgressBar } from "./CircularProgressBar";
import { getUserMarbleColor } from "../state/user/user.selector";
import UserProfileMarble from "./UserProfileMarble";
import Styles from "./UserProfileMenu.module.css";
import {
  isDarkTheme,
  Slider,
  toggleTheme,
  useClickOutside,
  useThemeContext,
} from "@hoylu/client-common";

const UserProfileMenu: React.FC<UserProfileDialogProps> = ({
  userEmail,
  isOrgAdmin,
  logout,
  managementPortalUrl,
  isWorkspaceExportEnabled,
  activateDashboardOption,
  isCreditLimitReached,
  userColor,
  licenseType,
  isUserLicensed,
  profile,
  showCreditLimitUi,
  effectiveTonnageUsage,
  close,
  toggleRef,
}) => {
  const dispatch = useDispatch();
  const themeContext = useThemeContext();
  const ref = useClickOutside(close, [toggleRef]);

  const strings = Localized.object("USER_PROFILE_WIDGET");
  const usageNote = isCreditLimitReached
    ? strings.USAGE_OVER_LIMIT
    : Localized.string(
        "USER_PROFILE_WIDGET.USAGE_UNDER_LIMIT",
        effectiveTonnageUsage
      );

  const isDarkThemeSelected = isDarkTheme(themeContext.theme);

  const showUpload = () => {
    activateDashboardOption({
      optionType: DashboardOption.UPLOAD,
    });
  };

  const invite = () =>
    activateDashboardOption({
      optionType: DashboardOption.INVITE,
    });

  return (
    <div ref={ref} className={Styles.userProfileMenu}>
      <div className={Styles.userProfileMenuContent}>
        {showCreditLimitUi && (
          <div className={Styles.marbleContainer}>
            <CircularProgressBar
              percentage={effectiveTonnageUsage}
              themeColor={userColor}
            >
              {isUserLicensed ? (
                <UserProfileMarble profile={profile} userColor={userColor} />
              ) : (
                <div className={Styles.tonnageUsage}>
                  {`${effectiveTonnageUsage}%`}
                </div>
              )}
            </CircularProgressBar>
            {isUserLicensed ? (
              <div className={Styles.license}>
                <span className={Styles.userName}>
                  {profile.firstName} {profile.lastName}
                </span>
                <span className={Styles.licenseType}>
                  {Localized.string(
                    "USER_PROFILE_WIDGET.PLAN_TITLE",
                    licenseType
                  )}
                </span>
              </div>
            ) : (
              <span className={Styles.noLicense}>{usageNote}</span>
            )}
          </div>
        )}
        <ul className={Styles.listContainer}>
          <li title={userEmail} className={Styles.email}>
            {userEmail}
          </li>
          {!isUserLicensed && showCreditLimitUi && (
            <li
              title={strings.UPGRADE}
              className={`${Styles.option} ${
                isCreditLimitReached ? Styles.creditLimitReached : ""
              }`}
              onClick={() =>
                window.open("https://manage.hoylu.com/u/billing", "_blank")
              }
              data-test-id={"user-menu-upgrade"}
            >
              <div className={`hoylu-ui-icons-star-filled ${Styles.icon}`} />
              <div className={Styles.optionText}>{strings.UPGRADE}</div>
            </li>
          )}
          <li
            title={strings.MANAGE_ACCOUNT}
            className={Styles.option}
            onClick={() => {
              dispatch(
                activateDashboardOption({
                  optionType: DashboardOption.MANAGE_ACCOUNT,
                })
              );
            }}
          >
            <div className={`hoylu-ui-icons-head ${Styles.icon}`} />
            <div className={Styles.optionText}>{strings.MANAGE_ACCOUNT}</div>
          </li>
          {isOrgAdmin && !!managementPortalUrl && (
            <li
              title={strings.MANAGEMENT_PORTAL}
              className={Styles.option}
              onClick={() => {
                window.open(encodeURI(managementPortalUrl), "_newtab");
              }}
            >
              <div className={`hoylu-ui-icons-construction2 ${Styles.icon}`} />
              <div className={Styles.optionText}>
                {strings.MANAGEMENT_PORTAL}
              </div>
            </li>
          )}
          {isWorkspaceExportEnabled && (
            <li className={Styles.option}>
              <div
                title={strings.UPLOAD}
                className={Styles.interactiveOption}
                onClick={showUpload}
              >
                <div className={`hoylu-ui-icons-upload ${Styles.icon}`} />
                <div className={Styles.optionText}>{strings.UPLOAD}</div>
              </div>
            </li>
          )}
          <li className={Styles.option}>
            <div
              className={Styles.interactiveOption}
              title={strings.INVITE_USER}
              onClick={invite}
            >
              <div className={`hoylu-ui-icons-send ${Styles.icon}`} />
              <div className={Styles.optionText}>{strings.INVITE_USER}</div>
            </div>
          </li>
          <li className={Styles.theme}>
            <div
              className={isDarkThemeSelected ? Styles.inactive : Styles.active}
            >
              {strings.THEME_LIGHT_TITLE}
            </div>
            <Slider
              checked={isDarkThemeSelected}
              onChange={() => toggleTheme(themeContext)}
              color={"var(--global--notification--Color)"}
              useCheckIndication={false}
              title={strings.SWITCH_COLOR_SCHEME}
            />
            <div
              className={isDarkThemeSelected ? Styles.active : Styles.inactive}
            >
              {strings.THEME_DARK_TITLE}
            </div>
          </li>
          <li className={Styles.option}>
            <div
              title={strings.LOGOUT}
              className={Styles.interactiveOption}
              onClick={logout}
            >
              <div className={`hoylu-ui-icons-export ${Styles.icon}`}></div>
              <div className={Styles.optionText}>{strings.LOGOUT}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  logout: logout.request,
  activateDashboardOption,
};

const mapStateToProps = (state: RootState) => ({
  userEmail: state.context.user.profile.email,
  profile: state.context.user.profile,
  token: state.context.user.token,
  managementPortalUrl: managementPortal(state),
  isWorkspaceExportEnabled: enableWorkspaceExport(state),
  isOrgAdmin: isOrganizationAdmin(state.context.user.token),
  isCreditLimitReached: isCreditLimitReached(state),
  effectiveTonnageUsage: effectiveTonnageUsage(state),
  userColor: getUserMarbleColor(state),
  licenseType: getUserLicenseType(state),
  isUserLicensed: isUserLicensed(state),
  showCreditLimitUi: showCreditLimitUi(state),
});

type UserProfileDialogProps = {
  close: () => void;
  toggleRef: React.RefObject<HTMLElement>;
} & ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileMenu);
