import { RootAction, isActionOf } from "typesafe-actions";
import { AjaxError } from "rxjs/ajax";
import * as microsoftTeams from "@microsoft/teams-js";
import {
  getLicenseInfo,
  getToken,
  getUserProfile,
  login,
  logout,
  registerEmail,
  skipValidationEmail,
  userIdUpdated,
} from "../user/user.actions";
import {
  populateAllWorkspaces,
  populateInitialWorkspaces,
  loadMoreWorkspaces,
  editWorkspace,
  showDashboard,
  getWorkspaceDetails,
  workspaceChannelReady,
  queueEditWorkspace,
  updateWorkspaceDetails,
  setWorkspacePassword,
  removeWorkspacePassword,
  workspaceShowingPrompt,
  activateDashboardOption,
  workspaceDocumentReady,
  addWorkspaceToProject,
  createWorkspaceFromTemplate,
  deleteWorkspace,
  toggleWorkspaceVisibility,
  leaveWorkspace,
} from "../workspaces/workspaces.actions";
import { AppState, AppContext } from "../root.reducer";
import { clearError } from "./mode.actions";
import { getWorkspaceById } from "../workspaces/workspaces.selector";
import { updateFlags } from "../config/config.actions";
import { getOrgPolicies, sendValidationEmail } from "../user/user.actions";
import { retryableFailures } from "../workspaces/retryableFailures";
import { DashboardOption } from "../workspaces/DashboardOption";
import { appendTeamsMode } from "../../utils/url.helpers";
import { FrameContexts } from "@microsoft/teams-js";
import { sendMessageToWorkspace } from "../../post-message-dispatch";
import { browserHistory as history } from "../../services/dependencies/syncHistory";
import { updateDocumentTitle } from "../../services/dependencies/workspaceDependencies";

export enum AppMode {
  LOADING_DASHBOARD = "LOADING_DASHBOARD",
  NOT_LOGGED_IN = "NOT_LOGGED_IN",
  BETA_NOT_ALLOWED = "BETA_NOT_ALLOWED",
  SKIPPABLE_VERIFIED_POLICY_RESTRICTED = "SKIPPABLE_VERIFIED_POLICY_RESTRICTED",
  NOT_VERIFIED_POLICY_RESTRICTED = "NOT_VERIFIED_POLICY_RESTRICTED",
  DASHBOARD = "DASHBOARD",
  LOADING_WORKSPACE = "LOADING_WORKSPACE",
  EDITING = "EDITING",
  ERROR = "ERROR",
}

// State diagram: https://app.hoylu.com/181819150

export default (state: AppState | undefined, action: RootAction): AppMode => {
  if (state?.mode === undefined) return AppMode.LOADING_DASHBOARD;

  const fromAnywhereMode = fromAnywhere(state, action);
  if (fromAnywhereMode !== state.mode) return fromAnywhereMode;
  switch (state.mode) {
    case AppMode.LOADING_DASHBOARD:
      return fromLoadingDashboard(state.context, action);
    case AppMode.NOT_LOGGED_IN:
      return fromNotLoggedIn(state.context, action);
    case AppMode.DASHBOARD:
      return fromDashboard(state.context, action);
    case AppMode.LOADING_WORKSPACE:
      return fromLoadingWorkspace(state.context, action);
    case AppMode.EDITING:
      return fromEditing(state.context, action);
    case AppMode.NOT_VERIFIED_POLICY_RESTRICTED:
    case AppMode.SKIPPABLE_VERIFIED_POLICY_RESTRICTED:
      return fromNotVerified(state, action);
    case AppMode.ERROR:
      return fromError(state.context, action);
    default:
      return state.mode;
  }
};

function fromAnywhere(state: AppState, action: RootAction) {
  if (isActionOf([logout.success, logout.failure], action))
    return AppMode.NOT_LOGGED_IN;
  if (
    isActionOf(updateFlags, action) &&
    state.context.config.isBeta &&
    action.payload.isAnonymous === false &&
    action.payload.enableBetaAccess === false
  ) {
    const betaFlagValue = action.payload.enableBetaAccess;
    if (betaFlagValue !== undefined && !betaFlagValue)
      return AppMode.BETA_NOT_ALLOWED;
  }

  // treat a failure to get org polices + the lack of permissions as an indicator that
  // the user is not verified and part of an org that has policy restrictions
  if (
    isActionOf(getOrgPolicies.failure, action) &&
    state.context.user.profile.permissions.read.length === 0
  ) {
    return AppMode.NOT_VERIFIED_POLICY_RESTRICTED;
  } else if (
    state.context.config.featureFlags.validateEmailNotification &&
    isActionOf(getUserProfile.success, action) &&
    !state.context.user.skipEmailValidation &&
    [AppMode.DASHBOARD, AppMode.LOADING_DASHBOARD].includes(state.mode) &&
    !action.payload.emailValidated
  ) {
    return AppMode.SKIPPABLE_VERIFIED_POLICY_RESTRICTED;
  }

  if (isActionOf(getWorkspaceDetails.failure, action)) {
    if (
      action.payload.workspaceId === state.context.workspaces.waitingToEditID &&
      (action.payload.error as AjaxError).status !== 401
    )
      return AppMode.ERROR;
  }

  return state.mode;
}

function fromLoadingDashboard(context: AppContext, action: RootAction) {
  if (isActionOf(editWorkspace, action)) {
    const workspace = getWorkspaceById(
      { context, mode: AppMode.DASHBOARD },
      action.payload.workspaceId
    );
    if (workspace) {
      if (workspace.isChannelReady) {
        return AppMode.EDITING;
      }
      return AppMode.LOADING_WORKSPACE;
    } else if (context.workspaces.waitingToEditID) {
      return AppMode.LOADING_WORKSPACE;
    }
    return AppMode.ERROR;
  }

  if (isActionOf(getToken.failure, action)) return AppMode.NOT_LOGGED_IN;
  if (isActionOf(getLicenseInfo.success, action)) {
    if (context.user.licenseInfo) {
      return AppMode.DASHBOARD;
    } else {
      return AppMode.LOADING_DASHBOARD;
    }
  }
  if (
    isActionOf(populateAllWorkspaces.success, action) ||
    isActionOf(populateInitialWorkspaces.success, action) ||
    isActionOf(loadMoreWorkspaces.success, action)
  ) {
    return context.workspaces.waitingToEditID
      ? AppMode.LOADING_WORKSPACE
      : AppMode.DASHBOARD;
  }

  return AppMode.LOADING_DASHBOARD;
}

function fromNotLoggedIn(context: AppContext, action: RootAction) {
  if (isActionOf([login.success, registerEmail.success], action))
    return AppMode.LOADING_DASHBOARD;
  return AppMode.NOT_LOGGED_IN;
}

function fromDashboard(context: AppContext, action: RootAction) {
  if (isActionOf(editWorkspace, action)) {
    const workspace = getWorkspaceById(
      { context, mode: AppMode.DASHBOARD },
      action.payload.workspaceId
    );
    if (workspace) {
      if (workspace.isChannelReady) {
        return AppMode.EDITING;
      }
      return AppMode.LOADING_WORKSPACE;
    } else if (context.workspaces.waitingToEditID) {
      return AppMode.LOADING_WORKSPACE;
    }
    return AppMode.ERROR;
  }
  if (isActionOf(queueEditWorkspace, action)) {
    const workspace = getWorkspaceById(
      { context, mode: AppMode.DASHBOARD },
      action.payload.workspaceId
    );
    if (workspace) {
      if (workspace.isChannelReady) {
        return AppMode.EDITING;
      }
      return AppMode.LOADING_WORKSPACE;
    }
  }
  if (isActionOf(addWorkspaceToProject.failure, action)) {
    return AppMode.ERROR;
  }
  if (
    isActionOf(retryableFailures, action) &&
    (action.payload.error as AjaxError).status !== 401
  ) {
    return AppMode.ERROR;
  }

  return AppMode.DASHBOARD;
}

function fromLoadingWorkspace(context: AppContext, action: RootAction) {
  if (isActionOf(workspaceShowingPrompt, action)) {
    return AppMode.EDITING;
  }
  if (isActionOf([workspaceChannelReady, workspaceDocumentReady], action)) {
    const workspace = getWorkspaceById(
      { context, mode: AppMode.LOADING_WORKSPACE },
      action.payload
    );
    if (!workspace?.isChannelReady) {
      return AppMode.LOADING_WORKSPACE;
    }
    if (context.config.teamsFrame === FrameContexts.settings) {
      microsoftTeams.getContext((context) => {
        microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
          microsoftTeams.settings.setSettings({
            websiteUrl: window.location.href,
            contentUrl: appendTeamsMode(window.location.href),
            entityId: context.entityId,
            suggestedDisplayName: document.title,
          });
          saveEvent.notifySuccess();
        });
      });
      microsoftTeams.settings.setValidityState(true);
    }
    if (context.config.teamsFrame === FrameContexts.sidePanel) {
      sendMessageToWorkspace(workspace.workspaceId, {
        action: "TOGGLE_FOCUS",
        isFocused: false,
      });
    }
    return AppMode.EDITING;
  }

  if (isActionOf([showDashboard, createWorkspaceFromTemplate], action))
    return AppMode.DASHBOARD;
  if (
    isActionOf(activateDashboardOption, action) &&
    action.payload.optionType === DashboardOption.UPSELL
  ) {
    return AppMode.DASHBOARD;
  }

  if (isActionOf(userIdUpdated, action)) {
    if (!context.user.isReloggedUser) {
      return AppMode.DASHBOARD;
    }
  }

  return AppMode.LOADING_WORKSPACE;
}

function fromEditing(context: AppContext, action: RootAction) {
  if (isActionOf(workspaceChannelReady, action)) {
    // Case: correct workspace pw has been submitted
    const workspace = getWorkspaceById(
      { context, mode: AppMode.EDITING },
      action.payload
    );
    if (workspace?.hasPassword) {
      return AppMode.LOADING_WORKSPACE;
    }
  }

  if (
    isActionOf(
      [
        showDashboard,
        deleteWorkspace.success,
        toggleWorkspaceVisibility.success,
        leaveWorkspace.success,
      ],
      action
    )
  ) {
    if (
      isActionOf(
        [
          deleteWorkspace.success,
          toggleWorkspaceVisibility.success,
          leaveWorkspace.success,
        ],
        action
      )
    ) {
      updateDocumentTitle("Hoylu");
      history.replace("/");
    }
    return AppMode.DASHBOARD;
  }

  if (
    isActionOf(
      [
        setWorkspacePassword.failure,
        removeWorkspacePassword.failure,
        updateWorkspaceDetails.failure,
      ],
      action
    ) &&
    (action.payload.error as AjaxError).status !== 401
  ) {
    return AppMode.ERROR;
  }
  return AppMode.EDITING;
}
function fromError(context: AppContext, action: RootAction) {
  if (isActionOf(clearError, action) && context.user.isLoggedIn === true) {
    return AppMode.DASHBOARD;
  }
  return AppMode.ERROR;
}

function fromNotVerified(state: AppState, action: RootAction) {
  if (isActionOf(sendValidationEmail.failure, action)) return AppMode.ERROR;
  if (isActionOf(sendValidationEmail.success, action))
    return AppMode.NOT_LOGGED_IN;
  if (isActionOf(skipValidationEmail.request, action)) return AppMode.DASHBOARD;
  return state.mode;
}
